import { gql } from "@apollo/client";
import { CONTENT_LIBRARY_REFETCH_QUERIES } from "@src/contexts/TagContext/TagContext";
import Button from "@src/deprecatedDesignSystem/components/Button";
import * as Modal from "@src/deprecatedDesignSystem/components/ModalComposition";
import RadioButton from "@src/deprecatedDesignSystem/components/RadioButton";
import Text from "@ui/text";
import { useModal } from "@src/hooks/useModal";
import { useToast } from "@src/hooks/useToast";
import { StyleSheet } from "aphrodite";
import { route } from "nextjs-routes";
import { FC, useState } from "react";
import {
  useCopyPathModalQuery,
  useCopyPathMutation,
} from "./CopyPathModal.generated";
import { cn } from "@src/ui/lib/utils";

type Props = {
  pathId: number;
};
const CopyPathModal: FC<Props> = ({ pathId }) => {
  const { closeModal } = useModal();
  const { addToast, addErrorToast } = useToast();
  const { data } = useCopyPathModalQuery({
    variables: {
      id: pathId,
    },
  });
  const [duplicateChildContent, setDuplicateChildContent] = useState(false);
  const [copyPath, { loading }] = useCopyPathMutation({
    refetchQueries: CONTENT_LIBRARY_REFETCH_QUERIES,
    onCompleted: (data) => {
      if (data.copyPath.success && data.copyPath.path?.id) {
        addToast({
          iconType: "star",
          message: `Module successfully duplicated! Only the structure of the module has been duplicated. The content within the module has not been duplicated.`,
        });
        closeModal();
        window.open(
          route({
            pathname: "/library/library-item/[id]",
            query: { id: data.copyPath.path.libraryItem?.id },
          }),
        );
      } else {
        addErrorToast({
          ...data.copyPath,
          callsite: "course_card_copy_course",
        });
      }
    },
  });
  return (
    <Modal.Modal>
      <Modal.ModalHeader>Duplicate Module</Modal.ModalHeader>
      <Modal.ModalBody>
        <Text type="P1" marginBottom={8} fontWeight="SemiBold">
          {data?.AdminPath?.libraryItem.name.en}
        </Text>
        <RadioButton
          label="Duplicate module structure and reuse courses, check-ins, and resources"
          checked={!duplicateChildContent}
          onClick={() => setDuplicateChildContent(false)}
        />
        <div className="mb-4 ml-6 mt-2 flex max-w-[500px] flex-col gap-1">
          <ul className="list-disc pl-5">
            <li className={cn("text-gray-7")}>
              <Text type="P2" className={cn("text-gray-7")} multiline>
                This duplicates the structure of the module AND pulls in the
                original version of the courses, check-ins and resources inside.
              </Text>
            </li>
            <li className={cn("text-gray-7")}>
              <Text type="P2" className={cn("text-gray-7")} multiline>
                When making edits to content within the module, it will update
                that content anywhere else it lives in Opus.
              </Text>
            </li>
          </ul>
        </div>
        <RadioButton
          label="Duplicate module structure and make a copy of all courses, check-ins and resources"
          checked={duplicateChildContent}
          onClick={() => setDuplicateChildContent(true)}
        />
        <div className="mb-4 ml-6 mt-2 flex max-w-[500px] flex-col gap-1">
          <ul className="list-disc pl-5">
            <li className={cn("text-gray-7")}>
              <Text type="P2" className={cn("text-gray-7")} multiline>
                This duplicates the structure of the module AND makes a copy
                version of the original courses, check-ins and resources.
              </Text>
            </li>
            <li className={cn("text-gray-7")}>
              <Text type="P2" className={cn("text-gray-7")} multiline>
                You can edit the copied version of the content and it will not
                make changes to the original version.
              </Text>
            </li>
          </ul>
        </div>
      </Modal.ModalBody>
      <Modal.ModalFooter>
        <Button
          text="Cancel"
          variant="Gray"
          onClick={() => closeModal()}
          styleDeclaration={styles.modalButton}
        />
        <Button
          text="Duplicate"
          variant="Primary"
          loading={loading}
          styleDeclaration={styles.modalButton}
          onClick={() => {
            copyPath({
              variables: {
                id: pathId,
                duplicateChildContent,
              },
            });
          }}
        />
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};

const styles = StyleSheet.create({
  modalButton: {
    flex: 1,
  },
});

export default CopyPathModal;

gql`
  query CopyPathModal($id: Int!) {
    AdminPath(id: $id) {
      ...CopyPathModal
    }
  }
  fragment CopyPathModal on Path {
    id
    libraryItem {
      id
      name {
        en
      }
    }
  }

  mutation CopyPath($id: Int!, $duplicateChildContent: Boolean!) {
    copyPath(id: $id, duplicateChildContent: $duplicateChildContent) {
      success
      path {
        id
        libraryItem {
          id
        }
      }
      error {
        code
      }
    }
  }
`;
